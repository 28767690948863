import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DirectorGuard from 'src/components/RouteGuards/DirectorGuard';
import RegionalGuard from 'src/components/RouteGuards/RegionalGuard';
import CountyGuard from 'src/components/RouteGuards/CountyGuard';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: '*',
  layout: DashboardLayout,
  guard: AuthProtect,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/general/AggregateOpsView'))
    },
    {
      exact: true,
      path: PATH_APP.general.userProfile,
      component: lazy(() => import('src/views/general/Profile'))
    },
    {
      exact: true,
      path: PATH_APP.general.sales,
      component: lazy(() => import('src/views/general/SalesInfoView'))
    },
    {
      exact: true,
      path: PATH_APP.general.agent,
      component: lazy(() => import('src/views/general/AgentMetricsView'))
    },
    {
      exact: true,
      path: PATH_APP.general.countysales,
      component: lazy(() => import('src/views/general/RegionaManageView'))
    },
    {
      exact: true,
      path: PATH_APP.general.regionalsales,
      component: lazy(() => import('src/views/general/DirectorView'))
    },
    {
      exact: true,
      path: PATH_APP.general.salesform,
      component: lazy(() => import('src/views/general/Sales/ZohoSalesForm'))
    },
    {
      exact: true,
      path: PATH_APP.general.zohoforms,
      component: lazy(() => import('src/views/general/Sales'))
    },

    {
      exact: true,
      path: PATH_APP.general.fualtyform,
      component: lazy(() => import('src/views/general/Sales/ZohoFaultyPartsForm'))
    },

    {
      exact: true,
      path: PATH_APP.general.repossesionform,
      component: lazy(() => import('src/views/general/Sales/RepossesionForm'))
    },

    {
      exact: true,
      path: PATH_APP.general.portfoliosurveyform,
      component: lazy(() => import('src/views/general/Sales/PortfolioSurvey'))
    },

    {
      exact: true,
      path: PATH_APP.general.agentonboarding,
      component: lazy(() => import('src/views/general/Sales/FieldRegister'))
    },

    {
      exact: true,
      path: PATH_APP.general.commission,
      component: lazy(() => import('src/views/general/Commission'))
    },

    {
      exact: true,
      path: PATH_APP.general.directorportfolio,
      guard: DirectorGuard,
      component: lazy(() => import('src/views/general/Portfolio/OverView'))
    },
    {
      exact: true,
      path: PATH_APP.general.salesagent,
      component: lazy(() => import('src/views/general/SalesAgentView'))
    },
    {
      exact: true,
      path: PATH_APP.general.portfolio,
      component: lazy(() => import('src/views/general/Portfolio/Portfolio'))
    },
    {
      exact: true,
      path: PATH_APP.general.regionalportfolio,
      guard: RegionalGuard,
      component: lazy(() => import('src/views/general/Portfolio/RegionalPortfolioView'))
    },
    {
      exact: true,
      path: PATH_APP.general.countyportfolio,
      guard: CountyGuard,
      component: lazy(() => import('src/views/general/Portfolio/CountyPorfolio'))
    },
    {
      exact: true,
      path: PATH_APP.general.agentportfolio,
      guard: CountyGuard,
      component: lazy(() => import('src/views/general/Portfolio/WardPortfolio'))
    },
    {
      exact: true,
      path: PATH_APP.general.singleagentportfolio,
      component: lazy(() => import('src/views/general/Portfolio/AgentPortfolio'))
    },
    {
      exact: true,
      path: PATH_APP.general.nonperformingporfolio,
      component: lazy(() => import('src/views/general/Portfolio/PortFolioInfoView/NonPerforming'))
    },
    {
      exact: true,
      path: PATH_APP.general.performingporfolio,
      component: lazy(() => import('src/views/general/Portfolio/PortFolioInfoView/PerformingBreakdown'))
    },

    {
      exact: true,
      path: PATH_APP.general.customerdetails,
      component: lazy(() => import('src/views/general/Portfolio/CustomerPortfolio'))
    },

    {
      exact: true,
      path: PATH_APP.general.hiring,
      component: lazy(() => import('src/views/general/Hiring/Hiring'))
    },
    {
      exact: true,
      path: PATH_APP.general.directorhiring,
      guard: DirectorGuard,
      component: lazy(() => import('src/views/general/Hiring/HiringDirectorView'))
    },
    {
      exact: true,
      path: PATH_APP.general.regionalhiring,
      guard: RegionalGuard,
      component: lazy(() => import('src/views/general/Hiring/HiringRegionalView'))
    },

    {
      exact: true,
      path: PATH_APP.general.countyhiring,
      guard: CountyGuard,
      component: lazy(() => import('src/views/general/Hiring/HiringCountyView'))
    },
    {
      exact: true,
      path: PATH_APP.general.agenthiringdetails,
      component: lazy(() => import('src/views/general/Hiring/HiringAgentDetails'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
