import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { hiringRegionList } from 'src/mock-data';

// - get data
export const getAllRegionHiring = createAsyncThunk('getRegionHiring', async () => {
    // const response = awai
    return hiringRegionList();
});


const initialState = {
    error: null,
    status: 'idle',
    data: []
};



const slice = createSlice({
    name: "allRegionHiring",
    initialState,
    extraReducers: {
        [getAllRegionHiring.pending](state, action) {
            state.status = 'fetching';
        },
        [getAllRegionHiring.fulfilled](state, action) {
            state.status = 'succeeded';
            state.data = action.payload;
        },
        [getAllRegionHiring.rejected](state, action) {
            state.error = action.payload;
        }

    }

});

export default slice.reducer;