import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    landingPageCurrentMonthSelected: true,
    salesViewCurrentMonthSelected: true,
    salesPerCountyCurrentMonthSelected: true
};

const currentDataSlice = createSlice({
    name: 'isCurrentData',
    initialState,
    reducers: {
        setDataStatus(state, action) {
            if (typeof action.payload.landingPageCurrentMonthSelected === 'boolean') {
                state.landingPageCurrentMonthSelected = action.payload.landingPageCurrentMonthSelected;
            }

            if (typeof action.payload.salesViewCurrentMonthSelected === 'boolean') {
                state.salesViewCurrentMonthSelected = action.payload.salesViewCurrentMonthSelected;
            }

            if (typeof action.payload.salesPerCountyCurrentMonthSelected === 'boolean') {
                state.salesPerCountyCurrentMonthSelected = action.payload.salesPerCountyCurrentMonthSelected;
            }

        }
    }
});



// Actions
export const { setDataStatus } = currentDataSlice.actions;

// Reducer
export default currentDataSlice.reducer;
