import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    agent: {}
};



const slice = createSlice({
    name: "hiringAgentDetails",
    initialState,
    reducers: {
        persistAgentDetails(state, action) {
            if (action.payload) {
                state.agent = action.payload;
            }
        }
    }
});


export default slice.reducer;

export const { persistAgentDetails } = slice.actions;




