import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

import { portfolioMockData } from 'src/mock-data.js';
import { getDoubleSum, getTotalPortfolio } from 'src/utils/getSum';

// - get portfolio

export const getPortfolioList = createAsyncThunk('allRegionPortfolioList/getPortfolioList', async (lastMonth = false) => {
    let data = await axios.get('/v1/field_ops/health-portfolio/', { params: { lastMonth } });
    return data;
});

const initialState = {
    error: null,
    fetchingStatus: 'idle',
    triggerFetch: false,
    isLoading: false,
    data: [],
    portfolio: {
        performing: 0,
        non_performing: 0,
        achieved: 0,
        target: 80
    }
};

const slice = createSlice({
    name: 'allRegionPortfolioList',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            if (action.payload === true) {
                state.fetchingStatus = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        }
    },
    extraReducers: {
        [getPortfolioList.pending](state, action) {
            state.fetchingStatus = 'loading';
            state.isLoading = true;
        },

        [getPortfolioList.fulfilled](state, action) {
            state.data = action.payload.data;
            state.fetchingStatus = 'succeeded';
            state.isLoading = false;
            const aggPorfolio = aggregatePorfolio(action.payload.data);
            state.portfolio.performing = aggPorfolio.a || 0;
            state.portfolio.non_performing = aggPorfolio.b || 0;
            state.portfolio.achieved = perfomancePreview(aggPorfolio.achieved, aggPorfolio.target);
        },

        [getPortfolioList.rejected](state, action) {
            state.error = true;
            state.isLoading = false;
        }
    }

});

// Actions
export const { resetFetchStatus } = slice.actions;

export default slice.reducer;

export const aggregatePorfolio = (data = []) => {
    return getTotalPortfolio(data);
};

export const perfomancePreview = (achieved, target) => {
    if (!achieved || !target) { return 0; }
    return Math.round(achieved / target * 100);
}



