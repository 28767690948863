/**
 * 
 * @param {* array of int data} data 
 * @returns  sum of all values in the array
 */
export const getSum = data => {
    if (!data) { return 0; }
    return data.reduce((a, b) => a + b, 0);
};

/**
 * 
 * @param {*list of data in an array form} data 
 * @param {*first key present in data objects} optA 
 * @param {*second key present in data objects} optB 
 * @returns sum of data in optA and data in optB
 */
export const getDoubleSum = (data, optA, optB) => {
    if (!data) { return { a: 0, b: 0 }; }
    return data.reduce((prev, curr) => {
        prev.a += curr[optA];
        prev.b += curr[optB];
        return prev;
    }, { a: 0, b: 0 });
};



export const getTotalPortfolio = (data) => {
    if (!data) { return { a: 0, b: 0, achieved: 0, target: 0 }; }


    return data.reduce((prev, curr) => {
        prev.a += curr.health_portfolio.payment.performing.count;
        prev.b += curr.health_portfolio.payment.non_performing.count;
        prev.achieved += curr.health_portfolio.payment.achieved;
        prev.target += curr.health_portfolio.payment.target;
        return prev;
    }, { a: 0, b: 0, achieved: 0, target: 0 });
};