import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { PATH_APP } from 'src/routes/paths';
import { useSelector } from 'react-redux';
import { selectUserRole } from 'src/utils/userRoleSelection';

// ----------------------------------------------------------------------

RegionalHiringGuard.propTypes = {
    children: PropTypes.node
};

function RegionalHiringGuard({ children }) {
    const { userRole } = useSelector(
        (state) => state.authJwt
    );

    const [isLoading, setState] = useState(true);


    setTimeout(() => {
        if (isLoading) {
            setState(!isLoading);
        }
    }, 1000);

    const selectedUserRole = selectUserRole((userRole || '').split(','))

    // roles that can access regional manager view
    const roles = ['sales director', 'regional sales manager'];

    const canAccess = roles.includes(selectedUserRole || '');


    if (!canAccess && isLoading === false) {
        return <Redirect to={PATH_APP.general.root} />;
    }


    return <>{children}</>;
}

export default RegionalHiringGuard;
