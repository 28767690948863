import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { countyHiring } from 'src/mock-data';

// - get data
export const getCountyHiring = createAsyncThunk('getCountyHiring', async () => {
    // const response = awai
    return countyHiring();
});


const initialState = {
    error: null,
    status: 'idle',
    data: []
};



const slice = createSlice({
    name: "countyHiring",
    initialState,
    extraReducers: {
        [getCountyHiring.pending](state, action) {
            state.status = 'fetching';
        },
        [getCountyHiring.fulfilled](state, action) {
            state.status = 'succeeded';
            state.data = action.payload;
        },
        [getCountyHiring.rejected](state, action) {
            state.error = action.payload;
        }

    }

});

export default slice.reducer;