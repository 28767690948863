import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getSalesSummary = createAsyncThunk('salesSummary/getSalesSummary', async params => {
    const response = await axios.get(`v1/field_ops/summary/`, { params: { res_type: 'sales' } });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    queryParamsPassed: false,
    salesSummary: {
        month_target: 0,
        percent_sales: 0,
        actual_sales: 0,
        last_month_percent: 0,
        last_month_sales: 0
    }
};

const slice = createSlice({
    name: 'salesSummary',
    initialState,
    reducers: {
        updateParamsState(state, action) {
            state.queryParamsPassed = action.payload;
        }
        // .. add more
    },
    extraReducers: {
        [getSalesSummary.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getSalesSummary.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.salesSummary = { ...action.payload.sales_summary };
            state.isLoading = false;
        },
        [getSalesSummary.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
export const { updateParamsState } = slice.actions;

// Reducer
export default slice.reducer;




