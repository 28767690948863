import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "src/utils/axios";


//-------------------------------------

export const getCustomerPortfolio = createAsyncThunk('agentCustomerList/getCustomerPortfolio', async (params) => {
    console.log(params);
    const joinUrl = params.regionId > 0 ? `region/${params.regionType}/${params.regionId}` : '';
    const customerPortfolio = await axios.get(`/v1/field_ops/health-portfolio/${joinUrl}`, { params: { lastMonth: params.lastMonth } });
    return customerPortfolio.data;
});


const initialState = {
    fetchingStatus: 'idle',
    error: null,
    isLoading: false,
    data: {
        health_portfolio: {
            payment: {
                achieved: 0,
                non_performing: { break_down: [] },
                performing: { break_down: [] },
                target: 0
            }
        },
        region: {},
        customers: []
    }
};



const actions = createSlice({
    name: 'agentCustomerList',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            if (action.payload === true) {
                state.fetchingStatus = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        }
    },
    extraReducers: {
        [getCustomerPortfolio.pending](state, action) {
            state.fetchingStatus = 'pending';
            state.error = false;
            state.isLoading = true;
        },
        [getCustomerPortfolio.fulfilled](state, action) {
            state.fetchingStatus = 'succeeded';
            state.error = false;
            state.isLoading = false;
            state.data = action.payload;
            try {
                state.data.customers = joinUsers(action.payload.health_portfolio.payment.performing.break_down, action.payload.health_portfolio.payment.non_performing.break_down);
            } catch (error) {
                console.log(error);
            }
        },
        [getCustomerPortfolio.rejected](state, action) {
            state.fetchingStatus = 'failed';
            state.error = true;
            state.isLoading = false;
        },

    }

});
// actions

export const { resetFetchStatus } = actions.actions;


export default actions.reducer;


export const joinUsers = (firstList, secondList) => {
    firstList = addPerformingStatus(firstList, 'performing');
    secondList = addPerformingStatus(secondList, 'non-performing');
    firstList.push(...secondList);
    return firstList;
};


export const addPerformingStatus = (data, status) => {
    data.forEach(row => {
        row.status = status;
    });
    return data;
};