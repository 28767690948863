

export const generateProductPerformance = (regionPerformance = []) => {
    const flattenProductPerformance = regionPerformance.reduce((prev, curr) => {
        const key = curr.name;
        if (!prev[key]) {
            prev[key] = { achieved: curr.performance.achieved, sales: curr.performance.sales, target: curr.performance.target };
        } else {
            prev[key].achieved += curr.performance.achieved;
            prev[key].sales += curr.performance.sales;
            prev[key].target += curr.performance.target;
        }
        return prev;
    }, {});

    return joinProductsByName(flattenProductPerformance);
};


export const joinProductsByName = (flattenProductPerformance = {}) => {
    const products = [];
    for (const i in flattenProductPerformance) {
        let product = { name: i, ...flattenProductPerformance[i] };
        products.push(product);
    }

    return products;
};
