import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from 'src/utils/axios';


// ----------------
export const getAgentSales = createAsyncThunk('getAgentSales/agentSales', async (params) => {
    const agentSales = await axios.get('v1/field_ops/sales-perfomance', { params: { lastMonth: params.lastMonth } });
    console.log(agentSales);

    return agentSales.data;
})

const initialState = {
    fetchingData: 'idle',
    error: null,
    isLoading: false,
    data: {
        customers: [],
        sales_summary: { actual: 0, target: 0 },
        procucts_performance: []
    }
}


const slice = createSlice({
    name: 'agentSales',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            state.fetchingData = 'idle';
            state.isLoading = true;
            state.error = false;
        }
    },
    extraReducers: {
        [getAgentSales.pending](state, action) {
            state.fetchingData = 'fetching';
            state.isLoading = true;
        },
        [getAgentSales.fulfilled](state, action) {
            state.fetchingData = 'succeeded';
            state.isLoading = false;
            state.data = action.payload;
        },
        [getAgentSales.rejected](state, action) {
            state.fetchingData = 'failed';
            state.isLoading = false;
            state.error = true;
        }

    }
})

// - actions

export const { resetFetchStatus } = slice.actions;



export default slice.reducer;