// - all user roles

export const userRoles = [
    {
        role: "sales director",
        category: 1
    },
    {
        role: "regional sales manager",
        category: 2
    },
    {
        role: "area sales manager",
        category: 3
    },
    {
        role: "agent",
        category: 4
    }
];