import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { countyPortfolio } from 'src/mock-data';


//  -
export const getCountyPortfolio = createAsyncThunk('countyPortfolio/getCountyPortfolio', async params => {
    const joinUrl = params.regionId > 0 ? `region/${params.regionType}/${params.regionId}` : '';
    const countyPortfolio = await axios.get(`/v1/field_ops/health-portfolio/${joinUrl}`, { params: { lastMonth: params.lastMonth } });
    return countyPortfolio.data;
});


const initialState = {
    error: null,
    fetchingCountyPorfolio: "idle",
    isLoading: null,
    currentCounty: null,
    data: {
        agents: [],
        health_portfolio: { payment: { performing: { count: 0 }, non_performing: { count: 0 } } },
        region: {}
    },
    portfolio: {
        performing: 0,
        non_performing: 0,
    }
};


const slice = createSlice({
    name: 'countyPortfolio',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            if (action.payload === true) {
                state.fetchingCountyPorfolio = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        },
        setCurrentPortfolioCounty(state, action) {
            state.currentCounty = action.payload.regionId;
        }
    },
    extraReducers: {
        [getCountyPortfolio.pending](state, action) {
            state.fetchingCountyPorfolio = 'loading';
            state.isLoading = true;
        },

        [getCountyPortfolio.fulfilled](state, action) {
            state.fetchingCountyPorfolio = 'succeeded';
            state.data = action.payload;
            state.portfolio.performing = state.data.health_portfolio.payment.performing.count;
            state.portfolio.non_performing = state.data.health_portfolio.payment.non_performing.count;
            state.isLoading = false;
        },

        [getCountyPortfolio.rejected](state, action) {
            state.error = true;
            state.isLoading = false;
        }
    }
});

// Actions
export const { resetFetchStatus, setCurrentPortfolioCounty } = slice.actions;




export default slice.reducer;

