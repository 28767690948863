import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    fetching: 'idle',
    error: false,
    salesDetails: {
        agent: {},
        customers: []
    },
};

const slice = createSlice({
    name: 'agentdetail',
    initialState,
    reducers: {
        // Clear data
        refetchData(state, action) {
            state.fetching = 'idle';
        },

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.fetching = 'loading';
        },

        // HAS ERROR
        hasError(state, action) {
            state.fetching = 'succeeded';
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET METRICS
        getCustomersSuccess(state, action) {
            state.fetching = 'failed';
            state.isLoading = false;
            state.salesDetails = action.payload;
        }
    }
});

// Actions

export const { refetchData } = slice.actions;

// Reducer
export default slice.reducer;


// ----------------------------------------------------------------------

export function getCustomers(agentId, lastMonth) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`v1/field_ops/monthly-sales-by-agent/${agentId}/`, { params: { lastMonth } });
            dispatch(slice.actions.getCustomersSuccess(response.data));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
