import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// --------------------------------------------------------------
import axios from 'src/utils/axios';
import { generateProductPerformance } from 'src/utils/common/productPerformance';

export const getRegionList = createAsyncThunk('regionList/getRegionList', async (lastMonth) => {
    const response = await axios.get(`v1/field_ops/sales-perfomance`, { params: { lastMonth } });
    return response.data;
});

const initialState = {
    error: null,
    status: "idle",
    isLoading: false,
    regionList: [],
    saleSummary: {
        targets: 0,
        sales: 0
    },
    productPerformance: [],
    topSellingRegion: {},
};


const regionSlice = createSlice({
    name: "regionList",
    initialState,
    reducers: {
        refetchData(state, action) {
            if (action.payload === true) {
                state.status = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        }

    },
    extraReducers: {
        [getRegionList.pending](state, action) {
            state.status = 'loading';
            state.isLoading = true;
        },

        [getRegionList.fulfilled](state, action) {
            state.status = 'succeeded';
            state.regionList = action.payload.regions;
            state.topSellingRegion = getTopSellingRegion(state.regionList);
            state.saleSummary.targets = action.payload.sales_summary.target;
            state.saleSummary.sales = action.payload.sales_summary.actual;
            state.isLoading = false;
            state.productPerformance = joinSalesByProduct(state.regionList);
        },

        [getRegionList.rejected](state, action) {
            state.status = 'failed';
            state.error = true;
            state.isLoading = false;
        }

    }
});

// actions

export const { refetchData } = regionSlice.actions;

// Reducer
export default regionSlice.reducer;


export const getTopSellingRegion = regionList => {
    if (!regionList) { return {}; }
    return regionList.sort((a, b) => a.sales_summary.actual - b.sales_summary.target)[0];
};

export const getTotalSales = regionList => {
    return regionList.reduce((prev, cur) => {
        prev.sales += cur.sales;
        prev.targets += cur.targets;
        return prev;
    }, { sales: 0, targets: 0 });
};

export const joinSalesByProduct = (regions = {}) => {
    const allRegionProducts = [];
    for (const region of regions) {
        if (region.procucts_performance) {
            allRegionProducts.push(...region.procucts_performance);
        }
    }
    return generateProductPerformance(allRegionProducts);
};