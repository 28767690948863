import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getAggMetrics = createAsyncThunk('aggMetrics/getAggMetrics', async region => {
    const url = region.regionId > 0 ? `region/${region.regionId}` : '';
    const response = await axios.get(`v1/field_ops/monthly-sales-summary/${url}`, { params: { lastMonth: region.lastMonth } });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    queryParamsPassed: false,
    aggMetrics: {
        month_target: 0,
        percent_sales: 0,
        actual_sales: 0,
        last_month_percent: 0,
        last_month_sales: 0
    },
    portfolio: {

    }
};

const slice = createSlice({
    name: 'aggmetrics',
    initialState,
    reducers: {
        updateParamsState(state, action) {
            state.queryParamsPassed = action.payload;
        }
        // .. add more
    },
    extraReducers: {
        [getAggMetrics.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getAggMetrics.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            state.aggMetrics = action.payload.sales_summary ?
                { ...action.payload.sales_summary } : { ...action.payload };
            state.portfolio = { ...action.payload.health_portfolio };
            state.isLoading = false;
        },
        [getAggMetrics.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
export const { updateParamsState } = slice.actions;

// Reducer
export default slice.reducer;




