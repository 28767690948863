import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import { getDoubleSum } from 'src/utils/getSum';
import { aggregatePorfolio, perfomancePreview } from './allRegionPortfolioList';



// - get porfolio for a region
export const getRegionalPortfolio = createAsyncThunk('regionalPortfolio/getRegionalPortfolio', async (params) => {
    const joinUrl = params.regionId > 0 ? `region/${params.regionType}/${params.regionId}` : '';
    const regionPortfolio = await axios.get(`/v1/field_ops/health-portfolio/${joinUrl}`, { params: { lastMonth: params.lastMonth } });
    return regionPortfolio;
});


const initialState = {
    error: null,
    fetchingData: 'idle',
    isLoading: false,
    currentRegion: null,
    filters: {
        lastMonthDirty: false,
        currentMonthDirty: false,
        lastMonthData: {},
        currentMonthData: {}
    },
    data: {
        region: {},
        health_portfolio: {},
        counties: []
    },
    portfolio: {
        performing: 0,
        non_performing: 0,
        achieved: 0,
        target: 80
    }
};


const slice = createSlice({
    name: 'regionalPortforlio',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            if (action.payload === true) {
                state.fetchingData = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        },
        setFilterHistory(state, action) {
            if (action.payload.lastMonth) {
                state.filters.lastMonthDirty = true;
                state.filters.lastMonthData = {};
            }
        },
        setCurrentPortfolioRegion(state, action) {
            state.currentRegion = action.payload.regionId;
        }
    },
    extraReducers: {
        [getRegionalPortfolio.pending](state, action) {
            state.fetchingData = 'loading';
            state.isLoading = true;
        },
        [getRegionalPortfolio.fulfilled](state, action) {
            state.fetchingData = 'succeeded';
            state.error = false;
            state.data = action.payload.data;
            state.portfolio.target = state.data.health_portfolio.payment.target || 80;
            const aggPorfolio = aggregatePorfolio(action.payload.data.counties);
            state.portfolio.performing = aggPorfolio.a;
            state.portfolio.non_performing = aggPorfolio.b;
            state.portfolio.achieved = perfomancePreview(aggPorfolio.achieved, aggPorfolio.target);
            state.isLoading = false;
        },
        [getRegionalPortfolio.rejected](state, action) {
            state.error = true;
            state.isLoading = false;
        }
    }

});


// Actions
export const { resetFetchStatus, setCurrentPortfolioRegion } = slice.actions;


export default slice.reducer;


export const getAggPortfolioSum = (data = []) => {
    return getDoubleSum([])
};
