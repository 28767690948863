import axios from "src/utils/axios";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
//--------------------------------------------------------------------

export const getWardPortfolio = createAsyncThunk('wardPortfolio/getWardPortfolio', async params => {
    const joinUrl = params.agentId > 0 ? `agent/${params.agentId}` : '';
    const customerPortfolio = await axios.get(`/v1/field_ops/health-portfolio/${joinUrl}`, { params: { lastMonth: params.lastMonth } });
    return customerPortfolio.data;
});

const initialState = {
    error: null,
    fetchingWardPortfolio: 'idle',
    isLoading: false,
    currentAgentId: null,
    data: {
        health_portfolio: {
            payment: {
                achieved: 0,
                non_performing: { break_down: [] },
                performing: { break_down: [] },
                target: 0
            }
        },
        region: {},
        customers: []
    }
};


const slice = createSlice({
    name: 'wardPortfolio',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            if (action.payload) {
                state.fetchingWardPortfolio = 'idle';
                state.isLoading = true;
                state.error = false;
            }
        },
        setInitialAgent(state, action) {
            state.currentAgentId = action.payload.agentId;
        }
    },
    extraReducers: {
        [getWardPortfolio.pending](state, action) {
            state.fetchingWardPortfolio = 'loading';
            state.isLoading = true;
        },

        [getWardPortfolio.fulfilled](state, action) {
            state.fetchingWardPortfolio = 'succeeded';
            state.error = false;
            state.isLoading = false;
            state.data = action.payload;
            console.log(action.payload);
            state.isLoading = false;
        },

        [getWardPortfolio.rejected](state, action) {
            state.fetchingWardPortfolio = 'failed';
            state.error = true;
            state.isLoading = false;
        }
    }
});

// action
export const { resetFetchStatus,setInitialAgent } = slice.actions;

export default slice.reducer;
