import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getCommissions = createAsyncThunk('commissions/getCommissions', async params => {
    const response = await axios.get(`v1/field_ops/commission/summary/`, { params: { dateFilter: params } });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    status: "idle",
    error: null,
    queryParamsPassed: false,
    commission: [],
    summary: {
        paid: 0,
        pending: 0
    },
    commissionBreakdown: []
};

const slice = createSlice({
    name: 'commission',
    initialState,
    reducers: {
        updateParamsState(state, action) {
            state.queryParamsPassed = action.payload;
        },
        resetFetchStatus(state, action) {
            if (action.payload === true) {
                state.status = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        },
        // .. add more
    },
    extraReducers: {
        [getCommissions.pending]: (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
        },
        [getCommissions.fulfilled]: (state, { payload }) => {
            state.status = 'succeeded';
            state.commission = payload;
            state.isLoading = false;

            // --
            state.summary = groupCommission(payload);
            state.commissionBreakdown = groupCommissionByProduct(payload);
            console.log(state.commissionBreakdown);
        },
        [getCommissions.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error;
            state.isLoading = false;
        },

    }
});

// Actions
export const { updateParamsState, resetFetchStatus } = slice.actions;

const groupCommission = (commision = []) => {
    return commision.reduce((prev, cur) => {
        if (cur.paid) {
            prev.paid += cur.amount;
        } else {
            prev.pending += cur.amount;
        }

        return prev;
    }, { paid: 0, pending: 0 });
};

const groupCommissionByProduct = (commision = []) => {
    const summary = commision.reduce((prev, curr) => {
        const key = curr.commission_applied;
        if (!prev[key]) {
            prev[key] = { name: key, paid: curr.paid ? curr.amount : 0, pending: curr.paid ? 0 : curr.amount, sales: 1 };
        } else {
            prev[key].sales += 1;
            if (curr.paid) {
                prev[key].paid += curr.amount;
            } else {
                prev[key].pending += curr.amount;
            }
        }

        return prev;
    }, {});

    const finalSummary = [];

    for (let i in summary) {
        if (i) {
            finalSummary.push(summary[i]);
        }
    }

    return finalSummary;
};

// Reducer
export default slice.reducer;




