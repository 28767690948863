import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    portfolioOverview: []
};


const slice = createSlice({
    name: 'portfolioOverview',
    initialState,
    reducers: {
        setPortfolioOverview(state, action) {
            state.portfolioOverview = action.payload;
        }

    }

});
// -

export const { setPortfolioOverview } = slice.actions;

export default slice.reducer;
