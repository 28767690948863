import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { joinSalesByProduct } from './allRegionList';
import { joinProductsByName } from 'src/utils/common/productPerformance';


// -----------------------------------------------
export const getRegionalSales = createAsyncThunk('regionalSales/getRegionalSales', async params => {
    const baseUrl = {
        regionalManager: 'v1/field_ops/sales-perfomance/',
        salesByRegion: `v1/field_ops/regional-monthly-sales/region/region/${params.regionId}`
    };
    let lastMonth = params.lastMonth;
    const mainUrl = params.regionId > 0 ? baseUrl.salesByRegion : baseUrl.regionalManager;
    const response = await axios.get(mainUrl, { params: { lastMonth } });
    return response.data;
});
// ----------------------

const initialState = {
    isLoading: false,
    error: null,
    regionalSales: {
        counties: [],
        sales_summary: { actual: 0, target: 0 },
        region: {
            name: '',
            type: '',
            manager: {},
            procucts_performance: []
        },
        productPerfomance: []
    },
    regionName: '',
    fetchRgsStatus: 'idle',
    currentRegionId: null,
};

const slice = createSlice({
    name: 'regionalSales',
    initialState,
    reducers: {
        // ----- On data refetch or click events then reset state
        resetRegSalesInnitialState(state, action) {
            if (action.payload === true) {
                state.fetchRgsStatus = 'idle';
                state.error = null;
                state.isLoading = true;
            }
        },
        setInitialRegion(state, action) {
            state.currentRegionId = action.payload.regionId;
        },
        checkCurrentRegion(state, action) {
            if (action.payload === true) {
                state.currentRegionId = 1;
            }
        }

    },
    extraReducers: {
        [getRegionalSales.pending](state, action) {
            state.fetchRgsStatus = 'loading';
            state.isLoading = true;
        },
        [getRegionalSales.fulfilled](state, action) {
            state.fetchRgsStatus = 'succeeded';
            // county sales
            state.regionalSales = action.payload;
            // region
            try {
                state.regionName = state.regionalSales.region.name || '';
            } catch (error) {
                console.log(error);
            }

            if ((state.regionalSales.region || { procucts_performance: [] }).procucts_performance.length) {
                state.regionalSales.productPerfomance = secondaryProductJoin(state.regionalSales.region.procucts_performance);
            } else {
                state.regionalSales.productPerfomance = joinSalesByProduct(state.regionalSales.counties);
            }
            state.isLoading = false;
        },
        [getRegionalSales.rejected](state, action) {
            state.fetchRgsStatus = 'failed';
            state.error = true;
            state.isLoading = false;
        }
    }
});

// Actions
export const { resetRegSalesInnitialState, setInitialRegion } = slice.actions;

// Reducer
export default slice.reducer;


export const secondaryProductJoin = products => {
    const sales = products.reduce((prev, curr) => {
        const key = curr.name;
        if (!prev[key]) {
            prev[key] = { sales: curr.performance.sales, target: curr.performance.target };
        } else {
            prev[key].sales += curr.performance.sales;
            prev[key].target += curr.performance.target;
        }
        return prev;
    }, {});

    return joinProductsByName(sales);
};
