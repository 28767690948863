
export const mockData = () => {
    return {
        "data": [
            {
                "id": 1, "region": "Nairobi", "manager": "Collins Kipkemoi", "sales": 50, "targets": 200
            },
            {
                "id": 2, "region": "Rift", "manager": "Mator Seleski", "sales": 20, "targets": 98
            },
            {
                "id": 3, "region": "Western", "manager": "Mary Nguru", "sales": 60, "targets": 250
            }
        ],
        "total": 10
    };
};

export const portfolioMockData = () => {
    return {
        "data": [
            {
                "id": 1, "region": "Nairobi", "manager": "Collins Kipkemoi", "performing": 121, "non_performing": 25, "performing_breakdown": [90, 31], "non_performing_breakdown": [10, 5, 10]
            },
            {
                "id": 2, "region": "Rift", "manager": "Mator Seleski", "performing": 70, "non_performing": 10, "performing_breakdown": [40, 35], "non_performing_breakdown": [2, 6, 2]
            },
            {
                "id": 3, "region": "Western", "manager": "Mary Nguru", "performing": 2, "non_performing": 200, "performing_breakdown": [1, 1], "non_performing_breakdown": [100, 50, 50]
            }
        ],
        "total": 10
    };
};

export const regionalPorfolio = () => {
    return {
        "data": [
            {
                "id": 1, "county": "Uasin Gishu", "agents": 10, "performing": 121, "non_performing": 25, "performing_breakdown": [100, 21], "non_performing_breakdown": [10, 15, 0]
            },
            {
                "id": 2, "county": "Turkana", "agents": 3, "performing": 70, "non_performing": 10, "performing_breakdown": [35, 35], "non_performing_breakdown": [2, 5, 3]
            },
            {
                "id": 3, "county": "Siaya", "agents": 40, "performing": 2, "non_performing": 200, "performing_breakdown": [0, 2], "non_performing_breakdown": [100, 50, 50]
            }
        ],
        "total": 10
    };
};


export const countyPortfolio = () => {
    return {
        agents: [
            { name: 'kipkemoi collins', phone: "0715775170", status: 1, achieved: 60, ward: "koisaram" },
            { name: 'Belinda Rose', phone: "0752244500", status: 0, achieved: 20, ward: "limutu" },
            { name: 'Naikara Momanyi', phone: "0722555064", status: 1, achieved: 54, ward: "kabere" },
            { name: 'Naikara Momanyi', phone: "0722555064", status: 1, achieved: 48, ward: "kabere" },
            { name: 'Nick Salat', phone: "0722555064", status: 1, achieved: 70, ward: "mogotio" },
            { name: 'Derick Mbatia', phone: "072255504", status: 0, achieved: 10, ward: "Wanye" },
            { name: 'John Nikolai', phone: "0722555064", status: 1, achieved: 60, ward: "Westi" },
        ],
        portfolio: { performing: 20, non_performing: 10 }
    };
};


export const wardPorfolio = () => {
    return {
        agent_details: {
            full_name: 'kipkemoi collins', mpesa_mobile: "0715775170", ward: "koisaram"
        },
        customers: [
            { name: 'Naikara Momanyi', phone: "0722555064", kit: "kabere", status: 'performing', day_in_arears: 0, last_payment_date: new Date().toLocaleString() },
            { name: 'Nick Salat', phone: "0722555064", kit: "mogotio", status: 'performing', day_in_arears: 0, last_payment_date: new Date().toLocaleString() },
            { name: 'Derick Mbatia', phone: "072255504", kit: "Wanye", status: 'non-performing', day_in_arears: 5, last_payment_date: new Date('"8/13/2021').toLocaleString() },
            { name: 'John Nikolai', phone: "0722555064", kit: "Westi", status: 'non-performing', day_in_arears: 15, last_payment_date: new Date('"8/03/2021').toLocaleString() },
        ],
        portfolio: { performing: 20, non_performing: 10, performing_breakdown: [10, 10], non_performing_breakdown: [2, 2, 6] },
        ward: 'Koisaram'
    };
};

export const hiringRegionList = () => {
    return [
        { region: "Kisumu", active_agents: 100, inactive_agents: 30 },
        { region: "Rift Valley", active_agents: 200, inactive_agents: 60 },
    ];
};


export const singleHiringList = () => {
    return [
        { region: "Turkana North", active_agents: 30, inactive_agents: 3 },
        { region: "Baringo", active_agents: 40, inactive_agents: 6 },
    ];
};

export const countyHiring = () => {
    return [
        { full_name: "Collins Kipkemoi", status: 1, date_joined: new Date().toLocaleDateString(), ward: 'Emining', mpesa_mobile: "0715775170" },
        { full_name: "John Doe", status: 1, date_joined: new Date().toLocaleDateString(), ward: 'Emining', mpesa_mobile: "0715775170" },
        { full_name: "Silva Romeo", status: 1, date_joined: new Date().toLocaleDateString(), ward: 'Emining', mpesa_mobile: "0715775170" },
        { full_name: "Niki Tash", status: 1, date_joined: new Date().toLocaleDateString(), ward: 'Emining', mpesa_mobile: "0715775170" },
        { full_name: "Selski Mani", status: 1, date_joined: new Date().toLocaleDateString(), ward: 'Emining', mpesa_mobile: "0715775170" },
        { full_name: "Syjavel Mick", status: 0, date_joined: new Date().toLocaleDateString(), ward: 'Emining', mpesa_mobile: "0715775170" },
    ];
};
