import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";


//-------------------------------------

export const getCustomerDetails = createAsyncThunk('customerDetails/getCustomerDetails', async (params) => {
    console.log(params);
    const customerPortfolio = await axios.get(`/v1/field_ops/health-portfolio/customer/${params.customerId}`, { params: { lastMonth: params.lastMonth } });
    return customerPortfolio.data;
});


const initialState = {
    fetchingStatus: 'idle',
    error: null,
    isLoading: false,
    data: {}
};



const actions = createSlice({
    name: 'agentCustomerList',
    initialState,
    reducers: {
        resetFetchStatus(state, action) {
            if (action.payload === true) {
                state.fetchingStatus = 'idle';
                state.error = null;
                state.isLoading = false;
            }
        }
    },
    extraReducers: {
        [getCustomerDetails.pending](state, action) {
            state.fetchingStatus = 'pending';
            state.error = false;
            state.isLoading = true;
        },
        [getCustomerDetails.fulfilled](state, action) {
            state.fetchingStatus = 'succeeded';
            state.error = false;
            state.isLoading = false;
            state.data = action.payload;
        },
        [getCustomerDetails.rejected](state, action) {
            state.fetchingStatus = 'failed';
            state.error = action.payload;
            state.isLoading = false;
        },

    }

});
// actions

export const { resetFetchStatus } = actions.actions;


export default actions.reducer;
