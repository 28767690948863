import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { singleHiringList } from 'src/mock-data';

// - get data
export const getSingleRegionHiring = createAsyncThunk('getSingleRegionHiring', async () => {
    // const response = awai
    return singleHiringList();
});


const initialState = {
    error: null,
    status: 'idle',
    data: []
};



const slice = createSlice({
    name: "SinleRegionHiring",
    initialState,
    extraReducers: {
        [getSingleRegionHiring.pending](state, action) {
            state.status = 'fetching';
        },
        [getSingleRegionHiring.fulfilled](state, action) {
            state.status = 'succeeded';
            state.data = action.payload;
        },
        [getSingleRegionHiring.rejected](state, action) {
            state.error = action.payload;
        }

    }

});

export default slice.reducer;