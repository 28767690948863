import { userRoles } from './userRoles';
// ------------------------
// determines which role should take predecence
// ------------------------

export const selectUserRole = (roles = []) => {
    // remove any white space
    roles = roles.map(role => role.trim());

    const foundRoles = [];

    if (roles.length === 1) {
        return roles[0];
    }

    for (const i of userRoles) {
        if (roles.includes(i.role)) {
            foundRoles.push(i);
        }
    }

    foundRoles.sort((a, b) => a.category - b.category);

    return foundRoles[0].role;
};