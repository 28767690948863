import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------


export const getPortfolioSummary = createAsyncThunk('portfolioSummary/getPortfolioSummary', async params => {
    const response = await axios.get(`v1/field_ops/summary/`, { params: { res_type: 'health' } });
    return response.data;
});

// ----------------------------------------------------------------------

const initialState = {
    isPortfolioLoading: false,
    portFolioStatus: "idle",
    error: null,
    queryParamsPassed: false,
    portfolioSummary: {
        achieved: {}
    }
};

const slice = createSlice({
    name: 'getPortfolioSummary',
    initialState,
    reducers: {
        updateParamsState(state, action) {
            state.queryParamsPassed = action.payload;
        }
        // .. add more
    },
    extraReducers: {
        [getPortfolioSummary.pending]: (state, action) => {
            state.portFolioStatus = 'loading';
            state.isPortfolioLoading = true;
        },
        [getPortfolioSummary.fulfilled]: (state, action) => {
            state.portFolioStatus = 'succeeded';
            state.portfolioSummary = { ...action.payload.health_portfolio };
            state.isPortfolioLoading = false;
        },
        [getPortfolioSummary.rejected]: (state, action) => {
            state.portFolioStatus = 'failed';
            state.error = action.error;
            state.isPortfolioLoading = false;
        },

    }
});

// Actions
export const { updateParamsState } = slice.actions;

// Reducer
export default slice.reducer;




