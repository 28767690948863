import axios from 'axios';

const instance = axios.create(
  {
    baseURL: process.env.REACT_APP_API_ROOT || ''
  }
);

instance.defaults.headers.common['Content-Type'] = 'application/json';

export default instance;