import axios from 'src/utils/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { secondaryProductJoin } from './regionalSales';

// ---------------------------------------

export const getSalesList = createAsyncThunk('salesList/getSalesList', async ({ lastMonth, regionId }) => {
    const baseUrl = {
        salesByCounty: `v1/field_ops/regional-monthly-sales/region/county/${regionId}`,
        areaManager: 'v1/field_ops/sales-perfomance/'
    };

    const mainUrl = regionId > 0 ? baseUrl.salesByCounty : baseUrl.areaManager;

    const response = await axios.get(mainUrl, { params: { lastMonth } });

    return response.data;
});

// ---------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    countySales: {
        agents: [],
        procucts_performance: [],
        sales_summary: {}
    },
    salesFetchStatus: 'idle',
    currentCounty: null
};

const slice = createSlice({
    name: 'saleslist',
    initialState,
    reducers: {
        // MORE
        resetAgentsInitialState(state, action) {
            if (action.payload) {
                state.error = null;
                state.salesFetchStatus = 'idle';
                state.isLoading = true;
            }
        },
        setInitialCounty(state, action) {
            state.currentCounty = action.payload.regionId;
        },
    },
    extraReducers: {
        [getSalesList.pending](state, action) {
            state.salesFetchStatus = 'loading';
            state.isLoading = true;
        },
        [getSalesList.fulfilled](state, action) {
            state.salesFetchStatus = 'succeeded';
            state.countySales = action.payload;

            if (state.countySales.procucts_performance) {
                state.countySales.procucts_performance = secondaryProductJoin(state.countySales.procucts_performance);
            }
            state.isLoading = false;
        },
        [getSalesList.rejected](state, action) {
            state.salesFetchStatus = 'failed';
            state.error = true;
            state.isLoading = false;
        }

    }
});

// Actions
export const { resetAgentsInitialState, setInitialCounty } = slice.actions;

// Reducer
export default slice.reducer;
