import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "src/utils/axios";

export const changeEmail = createAsyncThunk('email/changeEmail', async ({ email }) => {
    try {
        const response = await axios.post('/account/verify-email/', {
            email,
        });

        return response.data;

    } catch (error) {
        throw new Error(error);
    }
});

const initialState = {
    entities: { token: null },
    error: null,
};

const usersSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
        builder.addCase(changeEmail.fulfilled, (state, action) => {
            state.entities = action.payload;
        });

        builder.addCase(changeEmail.rejected, (state, action) => {
            if (action.payload) {
                // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
                state.error = action.payload.errorMessage;
            } else {
                state.error = action.error.message;
            }
        });
    },
});

export default usersSlice.reducer;


// verify email



export const verifyEmail = async ({ token }) => {
    try {
        const response = await axios.post('/account/update-email/', {
            token,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }

};