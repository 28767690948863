import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import userReducer from './slices/user';
import productReducer from './slices/product';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import notificationsReducer from './slices/notifications';
import metricsReducer from './slices/aggMetrics';
import salesListReduder from './slices/agentSaleList';
import agentDetailReducer from './slices/agentDetail';
import getSalesByProductReducer from './slices/salesByProduct';
import getRegionalSales from './slices/regionalSales';
import pastDataReducer from './slices/pastData';
import getRegionList from './slices/allRegionList';
import getAllPortfolioList from './slices/allRegionPortfolioList';
import getRegionalPortfolio from './slices/regionalPortfolioList';
import getCountyPortfolio from './slices/countyPortfolio';
import wardPortfolio from './slices/wardPortfolio';
import portfolioOverviewStats from './slices/portfolioOverviewStats';
import regionalHiringList from './slices/hiring/allRegionHiringList';
import singleRegionHiringList from './slices/hiring/singleRegionHiringList';
import countyHiringList from './slices/hiring/countyHiringSlice';
import hiringAgentDetails from './slices/hiring/hiringAgentDetails';
import agentCustomerPortfolio from './slices/portfolio/agentCustomerPortfolio';
import agentSales from './slices/sales/agentSales';
import customerDetails from './slices/portfolio/customerDetails';
import salesSummary from './slices/sales/salesSummary';
import portfolioSummary from './slices/portfolio/portfolioSummary';
import email from './slices/account/emailSlice';
import commission from './slices/sales/commission';



const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const productPersistConfig = {
  key: 'product',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const aggMetricsPersistConfig = {
  key: 'aggMetrics',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['navigation']
};

const salesListPersistConfig = {
  key: 'salesList',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['navigation']
};

const productSalesPersistConfig = {
  key: 'productSales',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['navigation']
};

const isPastDataPersistConfig = {
  key: 'isCurrentData',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['navigation']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  aggMetrics: persistReducer(aggMetricsPersistConfig, metricsReducer),
  salesList: persistReducer(salesListPersistConfig, salesListReduder),
  productSales: persistReducer(productSalesPersistConfig, getSalesByProductReducer),
  customers: agentDetailReducer,
  regionalSales: getRegionalSales,
  isCurrentData: persistReducer(isPastDataPersistConfig, pastDataReducer),
  regionList: getRegionList,
  allRegionPortfolioList: getAllPortfolioList,
  regionalPortfolio: getRegionalPortfolio,
  countyPortfolio: getCountyPortfolio,
  wardPortfolio,
  porfolioBreakown: portfolioOverviewStats,
  regionalHiringList,
  singleRegionHiringList,
  countyHiringList,
  hiringAgentDetails,
  agentCustomerPortfolio,
  agentSales,
  customerDetails,
  salesSummary,
  portfolioSummary,
  email,
  commission
});

export { rootPersistConfig, rootReducer };
